import React, { useState } from 'react';
import { BsFillShieldLockFill, BsTelephoneFill } from 'react-icons/bs';
import OtpInput from "otp-input-react";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from 'react-router-dom';

const Signin = () => {
  const navigate = useNavigate(); 

  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [password,setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError,setPasswordError]  = useState("");
  const [otpError, setOtpError] = useState("");
  const [otpVerifiedMessage, setOtpVerifiedMessage] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingForgetPassword, setLoadingForgetPassword] = useState(false);


  const sendOTP = async () => {
    setLoading(true);
    try {
      // Validate phone number input
      if (!ph) {
        setPhoneNumberError("Please enter your mobile number");
        setLoading(false);
        return;
      }

      // Make POST request to send OTP
      //const response = await fetch("http://192.168.0.102:1038/user/login", {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/login`, {

      
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile_number: ph,
          login_type: "phone",
        }),
      });
      const data = await response.json();
      console.log(data); // Log response from API
      setShowOTP(true); // Show OTP input after successfully sending OTP
      setOtp(data.otp);
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };


  // const loginbypassword = async () => {
  //   setLoading(true);
  //   try {
  //     // Validate phone number input
  //     if (!ph) {
  //       alert("Please enter your mobile number");
  //       setLoading(false);
  //       return;
  //     }
      
  //     if (!password) {
  //       alert("Please enter Password");
  //       setLoading(false);
  //       return;
  //     }
  //     // Make POST request to send OTP
  //     // const response = await fetch("http://192.168.0.105:1038/user/loginbypassword", {
  //       const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/loginbypassword`, {

      
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         mobile_number: ph,
  //         userPassword:password,
  //         login_type: "phone",
  //       }),
  //     });


  //     const data = await response.json();
  //     console.log("data------",data)

  //     localStorage.setItem('token', data.user.device_token);

  //     if(data.status==false){
  //       alert("Invaid UserName and Password")
  //     }
  //     else{
  //       navigate('/');
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  //   setLoading(false);
  // };



  const loginbypassword = async () => {
    setLoadingLogin(true);  // Set loading for login button
    try {
      if (!ph) {
        alert("Please enter your mobile number");
        setLoadingLogin(false);
        return;
      }
      if (!password) {
        alert("Please enter Password");
        setLoadingLogin(false);
        return;
      }
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/loginbypassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile_number: ph,
          userPassword: password,
          login_type: "phone",
        }),
      });
      if (!response.ok) {
        setLoadingLogin(false);
        alert("Failed to login. Please try again.");
        return;
      }

      const data = await response.json();

      console.log("login token:", data.user.device_token);
      if (data.status === false) {
        alert(data.error || "Invalid username or password");
      } else {
        localStorage.setItem('token', data.user.device_token);
        navigate('/');
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("An error occurred. Please try again later.");
    } finally {
      setLoadingLogin(false);  // Reset loading state
    }
  };
  

 


  const registernow = async ()=>{
    setLoadingForgetPassword(true);
    console.log("register")
    // navigate('/register');
    navigate('/login');
    setLoadingForgetPassword(false);


  }

  const verifyOTP = async () => {
    setLoading(true);
    try {
      // Make POST request to verify OTP
      
    //  const response = await fetch("http://192.168.0.102:1038/user/verify_otp", {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/verify_otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify({
          phone: ph,
          login_type: "phone",
          otp: otp,
        }),
      });
      const data = await response.json();
      console.log(data,"verify"); // Log response from API
      if (data.status) {
        // OTP verification successful
        setUser(data.user); // Update user state with verified user data
         // Save the token in localStorage
        localStorage.setItem('token', data.token);
        console.log("Pankaj",data);
        setOtpVerifiedMessage("OTP verified");
        setOtp("");
        // navigate('/category');
        navigate('/');
       
      } else {
        // OTP verification failed
        setOtpError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  const handlePhoneNumberChange = (e) => {
    setPh(e.target.value);
    setPhoneNumberError(e.target.value ? "" : "Please enter your mobile number");
  };


  const handlepasswordChange =(e) =>{
    setPassword(e.target.value);
    setPasswordError(e.target.value ? "" : "Please enter your  Password");
  }


  const forgetpassword = async ()=>{
    console.log("chencking")
    navigate('/forgetpassword');
  }



  console.log("password--",password)
  return (
    <>
     {/* <section className='desktop'>
        <section className='carro'>
          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="band1.jpg" className="d-block w-100" alt="band1.jpg" />
              </div>
              <div className="carousel-item">
                <img src="band2.jpg" className="d-block w-100" alt="band2.jpg" />
              </div>
              <div className="carousel-item">
                <img src="band3.jpg" className="d-block w-100" alt="band3.jpg" />
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </button>
          </div>
        </section>
      </section> */}
    <section className="bg-primary d-flex align-items-center justify-content-center vh-100">
      <div>
        {user ? (
          <h2 className='text-center text-black font-medium text-xl'>
            Successfully logged in
          </h2>
        ) : (
          <div className='w-80 flex flex-col gap-6 rounded-lg p-4'>
            <h1 className="text-center leading-normal text-warning font-medium text-1xl mb-6">
              Welcome to India's Number 1 Education Platform <br />
              <span className="text-blue-500">Pensbook Exam</span>
              <span className="text-blue-500">Sign IN</span>
            </h1>
            {!showOTP && (
              <>
                {/* <div className="bg-white text-blue-500 w-fit mx-auto p-4 rounded-full">
                  <BsTelephoneFill size={30} />
                </div> */}
                {/* <label
                  htmlFor="otp"
                  className="font-bold text-xl text-black text-center"
                >
                  continue with phone number
                </label> */}
                <div className="row ">
                <div className='col-md-4 mt-5'></div>
                <div className='col-md-4 mt-5'>
                  <input
                      type="tel"
                      value={ph}
                      onChange={handlePhoneNumberChange}
                      onBlur={handlePhoneNumberChange} // Optional: Trigger validation on blur
                      className="bg-gray-100 border border-gray-300 rounded p-2 w-full form-control"
                      placeholder="Enter your mobile number"
                      
                      required
                    />
                  </div>
                  <div className='col-md-4 mt-5'>
                  <input
                      type="tel"
                      value={password}
                      onChange={handlepasswordChange}
                      onBlur={handlepasswordChange} // Optional: Trigger validation on blur
                      className="bg-gray-100 border border-gray-300 rounded p-2 w-full form-control"
                      placeholder="Enter your Password"
                      
                      required
                    />
                  </div>
                 
                  <div className='col-md-4 mt-5'></div>
                  <div className='col-md-4 mt-5'></div>
                  <div className='col-md-12 mt-5 ml-5' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                  onClick={loginbypassword}
                  className="bg-blue-600 w-full btn btn-sm border btn-danger text-center flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loadingLogin  && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Login</span>
                </button>

                <button
                  onClick={registernow}
                  className="bg-blue-600 w-full btn btn-sm border  ml-4 btn-danger text-center flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loadingForgetPassword   && (
                    <CgSpinner size={20} className="mt-1  animate-spin" />
                  )}
                  <span>Register Now</span>
                </button>

                <button
                  onClick={forgetpassword}
                  className="bg-blue-600 w-full btn btn-sm border  ml-4 btn-danger text-center flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1  animate-spin" />
                  )}
                  <span>Forget Password</span>
                </button>

                  </div>
                  <div className='col-md-4'></div>



                </div>
                {/* <input
                  type="tel"
                  value={ph}
                  onChange={handlePhoneNumberChange}
                  onBlur={handlePhoneNumberChange} // Optional: Trigger validation on blur
                  className="bg-gray-100 border border-gray-300 rounded p-2 w-full"
                  placeholder="Enter your mobile number"
                  required
                /> */}
                {/* {phoneNumberError && (
                  <p className="text-red-500 text-sm">{phoneNumberError}</p>
                )} */}
                {/* <button
                  onClick={sendOTP}
                  className="bg-blue-600 w-full btn btn-sm btn-success flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Send OTP</span>
                </button> */}
              </>
            )}
            {showOTP && (
              <>
                <div className="bg-white text-blue-500 w-fit mx-auto p-4 rounded-full">
                  <BsFillShieldLockFill size={30} />
                </div>

                <label
                  htmlFor="otp"
                  className="font-bold text-xl text-black text-center"
                >
                  Enter your OTP

                </label>
                
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  autoFocus
                  className="opt-container"
                ></OtpInput>
                  {otpVerifiedMessage && (
                   <p className="text-green-500 text-sm">{otpVerifiedMessage}</p>
                  )}

                {otpError && (
                  <p className="text-red-500 text-sm">{otpError}</p>
                )}
                <button
                  onClick={verifyOTP}
                  className="bg-blue-600 btn-success w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Verify OTP</span>
                </button>
              </>
            )}
            
          </div>
        )}
      </div>
    </section>
    </>
  );
};

export default Signin;


