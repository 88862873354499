import React, { useState } from 'react';
import { BsFillShieldLockFill, BsTelephoneFill } from 'react-icons/bs';
import OtpInput from "otp-input-react";
import { CgSpinner } from "react-icons/cg";
import { generatePath, useNavigate } from 'react-router-dom';

const ForgetPassword = () => {
  const navigate = useNavigate(); 

  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [otpVerifiedMessage, setOtpVerifiedMessage] = useState("");
  const [randomOTP,setRandomOTP] = useState("");

//   console.log("otp--,",otp)

  const sendOTP = async () => {
    const Generateotp = Math.floor(1000 + Math.random() * 9000); // Generate a 4-digit OTP
   setRandomOTP(Generateotp)
  //  setShowOTP(Generateotp)
    console.log("newOtp--",Generateotp)
    setLoading(true);
    try {
      // Validate phone number input
      if (!ph) {
        alert("Enter Registered Mobile Number")
        setPhoneNumberError("Enter Registered Mobile Number");
        setLoading(false);
        return;
      }
      
    


      //  Make POST request to send OTP
    //    const response = await fetch("http://192.168.0.147:1038/user/forget_password_otp", {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/forget_password_otp`, { 
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile_number: ph,
          login_type: "phone",
          Generateotp:Generateotp,
        }),
      });
      
      const data = await response.json();
      console.log("ajith",data);
      if(data.success==true){
        setShowOTP(true); // Show OTP input after successfully sending OTP
      setOtp(data.otp);

      }
      else{
        alert(data.error)
        setShowOTP(false);
        return
      }
       // Log response from API
      
    
    } catch (error) {
      setShowOTP(false); // Hide OTP input in case of an exception
      console.error("Error:", error);
    }
    setLoading(false);
  };


  const verifyOTP = async () => {
    console.log("generateopt--", randomOTP, otp);
  
    if (Number(randomOTP) === Number(otp)) { // ✅ Ensure both are numbers
      navigate('/resetpassword', { state: { mobile_number: ph } }); // ✅ Pass mobile_number
    } else {
      alert("Invalid OTP");
    }
  };



  const handlebackpage = async () => {
    navigate('/signin');
    
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    
    // Allow only numbers and restrict to a maximum of 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setPh(value);
      // Display error if the value is not exactly 10 digits
      setPhoneNumberError(value.length === 10 ? "" : "Please enter a valid 10-digit mobile number");
    }
  };
  
  console.log("checking--", ph);

  return (
    <>
     {/* <section className='desktop'>
        <section className='carro'>
          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src="band1.jpg" className="d-block w-100" alt="band1.jpg" />
              </div>
              <div className="carousel-item">
                <img src="band2.jpg" className="d-block w-100" alt="band2.jpg" />
              </div>
              <div className="carousel-item">
                <img src="band3.jpg" className="d-block w-100" alt="band3.jpg" />
              </div>
            </div>
            <button className="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </button>
          </div>
        </section>
      </section> */}
    <section className="bg-primary d-flex align-items-center justify-content-center vh-100">
      <div>
        {user ? (
          <h2 className='text-center text-black font-medium text-xl'>
            Successfully logged in
          </h2>
        ) : (
          <div className='w-80 flex flex-col gap-6 rounded-lg p-4'>
            <h1 className="text-center leading-normal text-warning font-medium text-1xl mb-6">
              Welcome to India's Number 1 Education Platform <br />
              <span className="text-blue-500">Pensbook Exam</span>
             
            </h1>
            {/* <div>
                <h1>Enter Register Mobile No</h1>
            </div> */}
            {!showOTP && (
              <>
                {/* <div className="bg-white text-blue-500 w-fit mx-auto p-4 rounded-full">
                  <BsTelephoneFill size={30} />
                </div> */}
                {/* <label
                  htmlFor="otp"
                  className="font-bold text-xl text-black text-center"
                >
                  continue with phone number
                </label> */}
                <div className="row ">
                <div className='col-md-4 mt-5'></div>
                  <div className='col-md-4 mt-5'>
                  <input
                      type="tel"
                      value={ph}
                      onChange={handlePhoneNumberChange}
                      onBlur={handlePhoneNumberChange} // Optional: Trigger validation on blur
                      className="bg-gray-100 border border-gray-300 rounded p-2 w-full form-control"
                      placeholder="Enter  Registered mobile number"
                      
                      required
                    />
                  </div>
                  <div className='col-md-4 mt-5'></div>
                  <div className='col-md-4 mt-5'></div>
                  <div className='col-md-4 mt-5' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                  onClick={sendOTP}
                  className="bg-blue-600 w-full btn btn-sm btn-danger text-center flex gap-1 items-center justify-center py-2.5 text-white rounded" 
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Sumbit</span>
                </button>
                  </div>
                  <div className='col-md-4'></div>



                </div>
                {/* <input
                  type="tel"
                  value={ph}
                  onChange={handlePhoneNumberChange}
                  onBlur={handlePhoneNumberChange} // Optional: Trigger validation on blur
                  className="bg-gray-100 border border-gray-300 rounded p-2 w-full"
                  placeholder="Enter your mobile number"
                  required
                /> */}
                {/* {phoneNumberError && (
                  <p className="text-red-500 text-sm">{phoneNumberError}</p>
                )} */}
                {/* <button
                  onClick={sendOTP}
                  className="bg-blue-600 w-full btn btn-sm btn-success flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Send OTP</span>
                </button> */}
              </>
            )}
            {showOTP && (
              <>
                <div className="bg-white text-blue-500 w-fit mx-auto p-4 rounded-full" >
                  <BsFillShieldLockFill size={30} />
                </div>

                <label
                  htmlFor="otp"
                  className="font-bold text-xl text-black text-center"
                >
                  Enter your OTP

                </label>
                
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  autoFocus
                  className="opt-container"
                ></OtpInput>
                  {otpVerifiedMessage && (
                   <p className="text-green-500 text-sm">{otpVerifiedMessage}</p>
                  )}

                {otpError && (
                  <p className="text-red-500 text-sm">{otpError}</p>
                )}
                <button
                  onClick={verifyOTP}
                  className="bg-blue-600 btn-success w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                >
                  {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                  )}
                  <span>Verify OTP</span>
                </button>

                <button
                  onClick={handlebackpage}
                  className="bg-blue-600 btn-success w-full flex gap-1 items-center justify-center py-2.5 text-white rounded ml-2"
                >
                  
                  <span>Back</span>
                </button>
              </>
            )}
            
          </div>
        )}
      </div>
    </section>
    </>
  );
};

export default ForgetPassword;


