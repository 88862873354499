import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import Teststart from "./pages/Teststart";
import Start from "./pages/Start";
import StartSecond from "./pages/StartSecond";
import StartThird from "./pages/StartThird";
import FirstSolution from "./pages/FirstSolution";
import SecondSolution from "./pages/SecondSolution";
import ThirdSolution from "./pages/ThirdSolution";
import Testseriesall from "./pages/Testseriesall";
import Testseries from "./pages/Testseries";
import SearchTestSeries from "./components/category/SearchTestSeries";
import FinalResult from "./pages/FinalResult";
import Leaderboard from "./pages/Leaderboard";
import Exams from "./pages/Exams";
import Jankari from "./pages/Jankari";
import DeletionData from "./pages/DeletionData";
import Blog from "./pages/Blog";
import Blogs_Deatils from "./pages/Blogs_Details";
import Profile from "./pages/Profile";
import Resume from "./pages/Resume";

import Videos from "./pages/Videos";
import Privacy from "./pages/Privacy";
import Term from "./pages/Term";

import Login from "./components/login/login";
import Category from "./components/category/category"
import TestSerieslist from "./components/category/TestSerieslist";
import SubCategory from "./components/category/sub_category";
import RecommendedTest from "./components/category/RecommendedTest";
import ScheduledWise from "./components/category/sheduledWise";
import PopularTestSeries from "./components/category/popularTestSeries";
import SeriesDetails from "./components/category/testSeriesDetails";
import DetailsOfTest from "./components/category/detailsOfTest";
import SectionalDetails from "./components/category/sectionalDetails";
import PackageDetails from "./components/category/packageDetails";
import CoupanDetails from "./components/category/coupanDetails";



import ExamUserProfile from "./components/category/examUserTest";
// import App from '../App.css';

import './index.css'; 
import Signin from "./components/SignIN/signin";
import Register from "./components/Register/Register";
import ForgetPassword from "./components/Forget password/forgetpasword";
import SetPassword from "./components/ResetPassword/Resetpassword";


export default function App() {
  return (
    <div>
      
      <BrowserRouter>
        <Routes>
          <Route path="/" index element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/teststart/:test_id" element={<Teststart/>} />
          <Route path="/start/:test_id/:selectedLanguage/:selectedValue/:timeduration" element={<Start/>} />
          <Route path="/startsecond/:id/:test_id/:selectedValue/:timeduration" element={<StartSecond/>} />
          <Route path="/startthird/:id/:test_id/:selectedValue/:timeduration" element={<StartThird/>} />
          <Route path="/firstsolution/:id" element={<FirstSolution/>} />
          <Route path="/secondsolution/:id" element={<SecondSolution/>} />
          <Route path="/thirdsolution/:id" element={<ThirdSolution/>} />
           <Route path="/testseries" element={<Testseries/>} />
           <Route path="/testSerieslist/:subcategory_id" element={<TestSerieslist/>} />
           
          <Route path="/testseriesall" element={<Testseriesall/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/videos" element={<Videos/>} />
          <Route path="/privacy" element={<Privacy/>} />
          <Route path="/term" element={<Term/>} />
          <Route path="/login"   element={<Login/>} />


          <Route path="/signin"   element={<Signin/>} />
          <Route path="/register"   element={<Register/>} />
          <Route path="/forgetpassword"   element={<ForgetPassword/>} />
          <Route path="/resetpassword"   element={<SetPassword/>} />





          <Route path="/category" element={<Category/>} />
          <Route path="/finalresult/:test_id" element={<FinalResult/>} />
          <Route path="/leaderboard/:test_id" element={<Leaderboard/>} />
          <Route path="/exams/" element={<Exams/>} />
          <Route path="/perdayJanakari/" element={<Jankari/>} />
          <Route path="/deletiondata/" element={<DeletionData/>} />
          <Route path="/blog/" element={<Blog/>} />
          <Route path="/blogs_details/:id" element={<Blogs_Deatils/>} />
          <Route path="/Profile" element={<Profile/>} />
          <Route path="/resume" element={<Resume/>} />
          <Route path="/testseries/:subCategoryId" element={<RecommendedTest/>} />
          <Route path="/upcoming" element={<ScheduledWise type="upcoming" />} />
          <Route path="/popular" element={<PopularTestSeries type="popular" />} />
          <Route path="/testseries_details/:test_series_id" element={<SeriesDetails/>} />
         

          {/* <Route path="/detailsOfTest" element={<DetailsOfTest/>} />   */}
          <Route path="/detailsOfTest/:testId" element={<DetailsOfTest />} /> 
          <Route path="/section_details/:testId" element={<SectionalDetails/>} /> 
          <Route path="/package_details/:testId" element={<PackageDetails/>} /> 
          <Route path="/package_details/:catId/:subcatId/:test_series_id/:testId" element={<PackageDetails/>} /> 
          <Route path="/coupan_details/:test_series_id/:testId/:price_id" element={<CoupanDetails/>} /> 
          <Route path="/searchtestseries/:text" element={<SearchTestSeries/>} /> 
          
          {/* <Route path="/test_series/:subCategoryId" element={<TestSeries/>} /> */}
          {/* <Route path="/sub_category/:categoryId" element={<SubCategory/>} /> */}
         
          
        </Routes>
      </BrowserRouter>
      
    </div>
  )
}

