import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useParams, Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";

export default function ExamStructure() {
  const navigate = useNavigate();
  const [examStructure, setExamStructure] = useState([]);
  const [packageCategory, setPackageCategory] = useState([]);
  const [coupandetails, setCoupandetails] = useState([]);
  const [packageAll, setPackageAll] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItemDetails, setSelectedItemDetails] = useState([]);
  const [profile, setProfile] = React.useState([]);
  const [phone, setPhone] = React.useState([]);
  const [name, setName] = React.useState();
  const [email, setEmail] = React.useState();
  const [paymentResponse, setPaymentResponse] = React.useState();
  const { test_series_id } = useParams(0);
  const { testId } = useParams(1);
  const { price_id } = useParams(2);
  const [selectedCouponId, setSelectedCouponId] = useState(null);

  const razorpayKey = "rzp_test_rDJOkZAZKwmcXW";

  useEffect(() => {
    const authToken = localStorage.getItem("token");
    if (authToken) {
      const fetchCoupan = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/user/price`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
              params: {
                price_id: price_id,
              },
            }
          );
          console.log("Mohan", response);
          setPackageCategory(response.data);
          fetchdetails();
        } catch (error) {
          console.error("Error fetching category package", error);
        }
      };
      const fetchdetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/user/coupon`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
              params: {
                test_series_id: test_series_id,
              },
            }
          );
          // console.log("Mohan2", response);
          setCoupandetails(response.data);
          console.log("Mohan2", coupandetails);
        } catch (error) {
          console.error("Error fetching category package", error);
        }
      };

      fetchCoupan();
      fetchprofile();
    } else {
      navigate("/login");
    }
  }, [price_id, test_series_id, navigate]);

  // Function to dynamically load the Razorpay script
  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const initiatePayment = async () => {


    if(selectedCouponId== null || selectedCouponId.length==0){
      alert("please select coupon Id")
      return
    }

    const authToken = localStorage.getItem("token");

    if (!authToken) {
      alert("Authorization token is missing. Please log in again.");
      return;
    }

    try {
      // Step 1: Load Razorpay Script
      const isScriptLoaded = await loadRazorpayScript();
      if (!isScriptLoaded) {
        alert(
          "Failed to load Razorpay SDK. Please check your internet connection."
        );
        return;
      }

      // Ensure packageCategory and price are properly available
      if (
        !packageCategory ||
        !packageCategory.price ||
        !packageCategory.price.price
      ) {
        alert("Invalid package data. Please try again.");
        return;
      }

      // Step 2: Create Order
      // const response = await fetch('http://localhost:1038/user/create-order',
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/user/create-order`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            amount: packageCategory.price.price, // Replace with actual amount
            currency: "INR",
          }),
        }
      );

      // Check for HTTP errors
      if (!response.ok) {
        throw new Error(`Error creating order: ${response.statusText}`);
      }

      const data = await response.json();
      if (data.success) {
        const { order } = data;

        // Step 3: Razorpay Payment Options
        const options = {
          key: "rzp_test_rDJOkZAZKwmcXW", // Replace with your Razorpay key
          amount: order.amount,
          currency: order.currency,
          name: "Pensbook",
          description: "Test Transaction",
          order_id: order.id,
          handler: async function (response) {
            // Step 4: Verify Payment

            // const verifyResponse = await fetch('http://localhost:1038/user/verify-payment',

            const verifyResponse = await fetch(
              `${process.env.REACT_APP_BASE_URL}/user/verify-payment`,

              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_signature: response.razorpay_signature,
                }),
              }
            );

            // Check for HTTP errors in payment verification
            if (!verifyResponse.ok) {
              throw new Error(
                `Error verifying payment: ${verifyResponse.statusText}`
              );
            }

            const verifyData = await verifyResponse.json();
            if (verifyData.success) {
              alert("Payment successful!");

              try {
                // Ensure that price_id and selectedCouponId are available
                if (!price_id || !selectedCouponId) {
                  throw new Error("Missing price_id or selectedCouponId.");
                }

                console.log("response-----", response);
                // Payment mode, transaction ID, and status from Razorpay response
                const paymentDetails = {
                  test_series_id:test_series_id,
                  test_id:testId,
                  order_id: response.razorpay_order_id,
                  payment_id: response.razorpay_payment_id,
                  price_id: price_id,
                  coupon_id: selectedCouponId,
                  payment_mode: response.payment_mode, // Extracted from Razorpay response
                  transaction_id: response.razorpay_payment_id, // You can use payment_id as transaction ID
                  bank_ref: response.bank_ref_id || "", // If bank_ref_id is available
                  // payment_status: response.status, // The status of the payment (e.g., 'captured')
                };

                // Step 5: Place Order with additional payment details
                // const orderProductResponse = await fetch('http://localhost:1038/user/order_product',

                const orderProductResponse = await fetch(
                  `${process.env.REACT_APP_BASE_URL}/user/order_product`,

                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${authToken}`,
                    },
                    body: JSON.stringify(paymentDetails),
                  }
                );

                const orderProductData = await orderProductResponse.json();

                if (orderProductResponse.ok) {
                  console.log("Order placed successfully:", orderProductData);
                  // alert(orderProductData.message);

                
                } else {
                  console.error(
                    "Failed to place order:",
                    orderProductData.message || "Unknown error"
                  );
                  throw new Error(
                    orderProductData.message || "Error placing order"
                  );
                }
              } catch (error) {
                console.error("Error placing order:", error);
                alert(`Order placement failed: ${error.message}`);
              }
            } else {
              alert("Payment verification failed");
            }
          },
          prefill: {
            name: name,
            email: email,
            contact: phone,
          },
          theme: {
            color: "#3399cc",
          },
        };

        // Step 6: Open Razorpay
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        alert("Failed to create order. Please try again.");
        console.error("Error creating order:", data.message);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      alert(`An error occurred: ${error.message}`);
    }
  };

  const fetchprofile = async () => {
    try {
      const authToken = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/profile`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);

      setProfile(response.data);
      setPhone(response.data.user.phone);
      setEmail(response.data.user.email);
      setName(response.data.user.name);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // const handleItemClick = async (itemId) => {
  //   try {
  //     const authToken = localStorage.getItem("token");
  //     if (authToken != null) {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL}/user/get_tests_of_test_series?exam_structure_id=${itemId}&test_series_id=${test_series_id}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${authToken}`,
  //           },
  //         }
  //       );
  //       setSelectedItemDetails(response.data);
  //       console.log(selectedItemDetails);
  //     } else {
  //       const response = await axios.get(
  //         `${process.env.REACT_APP_BASE_URL}/user/get_tests_of_test_series_without_login?exam_structure_id=${itemId}&test_series_id=${test_series_id}`,
  //         {
  //           headers: {
  //             Authorization: "",
  //           },
  //         }
  //       );
  //       console.log("RAHUL", response.data);
  //       setSelectedItemDetails(response.data);
  //     }
  //   } catch (error) {
  //     setSelectedItemDetails([]);
  //     console.error("Error fetching test series:", error.message);
  //   }
  // };

  const handleCouponClick = (couponId) => {
    console.log("couponId", couponId);
    setSelectedCouponId(couponId);
    console.log(`Selected Coupon ID: ${couponId}`); // You can log it or perform any other actions
  };

  return (
    <>
      {/* <section className='t'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
           
            </div>
        </div>
        </div> 
      </section> */}

      <section className="xs">
        <div className="container">
          <div className="row t">
            <div className="col-md-12">
              <h6 className="text-primary text-uppercase">
                {packageCategory.price?.title}
              </h6>
            </div>

            <div className="col-md-6">
              <h6 className="text-primary text-uppercase">
                {packageCategory.price?.price}
              </h6>
            </div>
            <div className="col-md-6">
              <h6 className="text-primary text-uppercase">
                {packageCategory.price?.duration} Months
              </h6>
            </div>

            <div className="col-md-12">
              <hr />
            </div>

            {coupandetails &&
              coupandetails.coupon?.map((item, index) => (
                <div
                  className={`col-md-12 mb-2 p-3 ${
                    selectedCouponId === item._id ? "bg-success" : "bg-info"
                  }`}
                  onClick={() => handleCouponClick(item._id)}
                >
                  <div className="row text-light">
                    <div className="col-md-3">
                      <h5>{item.title}</h5>
                    </div>
                    <div className="col-md-3">
                      <span>{item.desc}/-</span>
                    </div>
                  </div>
                </div>
              ))}
            <div className="col-md-12">
              <hr />
            </div>
            <div className="col-md-12">
              <a
                className="btn btn-sm btn-danger form-control"
                onClick={initiatePayment}
              >
                Order
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
